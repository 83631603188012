import { CustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import { TemplateEnum } from '@eo-storefronts/eo-core'
import StylesResetPasswordServiceInterface
  from '~/src/pages/auth/reset-password/styles/StylesResetPasswordServiceInterface'
import StylesResetPasswordServiceStyleTwo
  from '~/src/pages/auth/reset-password/styles/StylesResetPasswordServiceStyleTwo'
import StylesResetPasswordServiceStyleThree
  from '~/src/pages/auth/reset-password/styles/StylesResetPasswordServiceStyleThree'
import StylesResetPasswordServiceStyleOne
  from '~/src/pages/auth/reset-password/styles/StylesResetPasswordServiceStyleOne'

const getResetPasswordTemplateService = (style: TemplateEnum | null, muiTheme: CustomMuiTheme): StylesResetPasswordServiceInterface => {
  switch (style) {
    case TemplateEnum.STYLE_TWO:
      return new StylesResetPasswordServiceStyleTwo(muiTheme)
    case TemplateEnum.STYLE_THREE:
      return new StylesResetPasswordServiceStyleThree(muiTheme)
    default:
      return new StylesResetPasswordServiceStyleOne(muiTheme)
  }
}

export default getResetPasswordTemplateService
