import { useLayout } from '~/src/hooks/layout/useLayout'
import { useCustomMuiTheme } from '~/src/hooks/useCustomMuiTheme'
import useGetPageStyle from '~/src/hooks/layout/useGetPageStyle'
import StylesResetPasswordServiceInterface
  from '~/src/pages/auth/reset-password/styles/StylesResetPasswordServiceInterface'
import getResetPasswordTemplateService from '~/src/pages/auth/reset-password/styles/GetResetPasswordTemplateService'

const useGetResetPasswordTemplate = (): StylesResetPasswordServiceInterface => {
  const muiTheme = useCustomMuiTheme()
  const pageStyle = useGetPageStyle('resetPassword')

  useLayout('resetPassword')

  return getResetPasswordTemplateService(pageStyle, muiTheme)
}

export default useGetResetPasswordTemplate
