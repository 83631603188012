import { SxProps } from '@mui/material'
import TemplateServiceStyleBase from '~/src/utils/template/TemplateServiceStyleBase'
import StylesResetPasswordServiceInterface
  from '~/src/pages/auth/reset-password/styles/StylesResetPasswordServiceInterface'

export default class StylesResetPasswordServiceStyleTwo extends TemplateServiceStyleBase implements StylesResetPasswordServiceInterface {
  public getPageSx(): SxProps {
    return {
      mt: 'var(--eo-safe-area-top)',
      p: 2,
      minHeight: `calc(100vh - ${this.muiTheme.spacing(2)} * 2 - var(--eo-safe-area-top))`
    }
  }

  public getContainerSx(): SxProps {
    return {
      my: 4,
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      '.lf-player-container > #lottie': {
        width: '100%',
        height: '35vh',
        [this.muiTheme.breakpoints.down('md')]: {
          height: '25vh'
        }
      },
      [this.muiTheme.breakpoints.only('xs')]: {
        width: '100%',
        p: 0,
        m: 0
      },
      [this.muiTheme.breakpoints.only('sm')]: {
        width: '80%'
      },
      [this.muiTheme.breakpoints.only('md')]: {
        width: '40%'
      },
      [this.muiTheme.breakpoints.up('2k')]: {
        width: '40%'
      }
    }
  }

  public getTitleSx(): SxProps {
    return {
      color: 'background.contrastText'
    }
  }

  public getContainerActionButtonSx(): SxProps {
    return {
      textTransform: 'none'
    }
  }
}

