import Button from '~/src/components/mui-wrappers/buttons/Button'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import { useSearchParams } from 'react-router-dom'
import { ResetPasswordFormInterface } from '~/src/types/forms/ResetPasswordFormInterface'

interface Props {
  loading: boolean,
  form: FormikProps<ResetPasswordFormInterface>,
}

const ResetPasswordFormActions = ({ loading, form }: Props) => {
  const { t } = useTranslations()
  const [ searchParam ] = useSearchParams()
  const token = searchParam.get('token')

  return (
    <Button
      loading={loading}
      disabled={!form.dirty || !form.isValid || !token}
      variant='contained'
      type='submit'
      sx={{
        gridArea: 'button',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '1em',
        mt: 4
      }}
    >
      {t('resetPassword.actions.reset')}
    </Button>
  )
}

export default ResetPasswordFormActions
