import { useState } from 'react'
import { HttpError } from '@eo-storefronts/eo-core'
import { resetPassword } from '~/src/services/CustomerService'
import { ResetPasswordFormInterface } from '~/src/types/forms/ResetPasswordFormInterface'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'
import RoutesEnum from '~/src/router/enums/routes.enum'

interface ReturnsType {
  resetPassword(payload: ResetPasswordFormInterface, token: string, validity: string): void,

  error: string | null,
  loading: boolean,
}

const useResetPassword = (): ReturnsType => {
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const [ error, setError ] = useState<string | null>(null)
  const [ loading, setLoading ] = useState<boolean>(false)

  const action = async (payload: ResetPasswordFormInterface, token: string, validity: string) => {
    setError(null)
    setLoading(true)

    resetPassword(payload.password, payload.confirmPassword, token, validity)
      .then(() => {
        push(resolve(RoutesEnum.LOGIN))
      })
      .catch((error: HttpError) => {
        setError(error.message)
      })
      .finally(() => setLoading(false))
  }

  return { resetPassword: action, error, loading }
}

export {
  useResetPassword
}
