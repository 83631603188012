import { Box } from '@mui/material'
import FormError from '~/src/components/error/FormError'
import { FormikProps, useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslations } from '~/src/hooks/useTranslations'
import ResetPasswordFormFields from '~/src/components/auth/reset-password/ResetPasswordFormFields'
import ResetPasswordFormActions from '~/src/components/auth/reset-password/ResetPasswordFormActions'
import { useSearchParams } from 'react-router-dom'
import { useResetPassword } from '~/src/hooks/auth/useResetPassword'
import { ResetPasswordFormInterface } from '~/src/types/forms/ResetPasswordFormInterface'

const ResetPasswordForm = () => {
  const [ searchParams ] = useSearchParams()
  const token = searchParams.get('token')
  const timestamp = searchParams.get('timestamp')
  const { error, loading, resetPassword } = useResetPassword()
  const { t } = useTranslations()
  const formik: FormikProps<ResetPasswordFormInterface> = useFormik<ResetPasswordFormInterface>({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .required(t('errors.passwordRequired')),
      confirmPassword: yup
        .string()
        .oneOf([ yup.ref('password') ], t('errors.passwordMismatch'))
        .required(t('errors.passwordRequired'))
    }),
    onSubmit: (values => {
      resetPassword(values, decodeURIComponent(token!), decodeURIComponent(timestamp!))
    })
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {error && <FormError>{error}</FormError>}

        <ResetPasswordFormFields form={formik}/>

        <ResetPasswordFormActions
          form={formik}
          loading={loading}
        />
      </Box>
    </form>
  )
}

export default ResetPasswordForm
