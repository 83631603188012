import { Box } from '@mui/material'
import { useTranslations } from '~/src/hooks/useTranslations'
import { FormikProps } from 'formik'
import FormikTextField from '~/src/components/mui-wrappers/fields/FormikTextField'
import { ResetPasswordFormInterface } from '~/src/types/forms/ResetPasswordFormInterface'

interface Props {
  form: FormikProps<ResetPasswordFormInterface>,
}

const ResetPasswordFormFields = ({ form }: Props) => {
  const { t } = useTranslations()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4
      }}
    >
      <FormikTextField
        TextFieldProps={{
          form,
          color: 'content',
          name: 'password',
          type: 'password',
          label: t('resetPassword.fields.password')
        }}
      />

      <FormikTextField
        TextFieldProps={{
          form,
          color: 'content',
          name: 'confirmPassword',
          type: 'password',
          label: t('resetPassword.fields.confirmPassword')
        }}
      />
    </Box>
  )
}

export default ResetPasswordFormFields
