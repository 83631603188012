import { Box, Container, Typography } from '@mui/material'
import DocumentTitleInjector from '~/src/components/root/DocumentTitleInjector'
import RoutesEnum from '~/src/router/enums/routes.enum'
import SlideFromRight from '~/src/components/mui-wrappers/animations/motion/SlideFromRight'
import useGetResetPasswordTemplate from '~/src/pages/auth/reset-password/styles/useGetResetPasswordTemplate'
import { Player } from '@lottiefiles/react-lottie-player'
import Title from '~/src/components/mui-wrappers/Title'
import { useTranslations } from '~/src/hooks/useTranslations'
import ResetPasswordForm from '~/src/components/auth/reset-password/ResetPasswordForm'

const ResetPassword = () => {
  const pageName: keyof typeof RoutesEnum = 'RESET_PASSWORD'
  const { t } = useTranslations()
  const styles = useGetResetPasswordTemplate()

  return (
    <SlideFromRight
      pathname={pageName}
      transition={{
        duration: 0.5
      }}>
      <DocumentTitleInjector pageName={pageName}/>

      <Box sx={styles.getPageSx()}>
        <Container sx={styles.getContainerSx()}>
          <Player
            src='/images/lotties/reset-password.json'
            autoplay
            loop
            style={{ width: '100%' }}/>

          <Title
            variant='h4'
            sx={styles.getTitleSx()}>
            {t('resetPassword.title')}
          </Title>

          <Typography
            component='p'
            sx={{
              whiteSpace: 'break-spaces'
            }}>
            {t('resetPassword.description')}
          </Typography>

          <ResetPasswordForm/>
        </Container>
      </Box>
    </SlideFromRight>
  )
}

export default ResetPassword
